// TODO Load ApiService here
// https://blog.logrocket.com/vuex-showdown-mutations-vs-actions/
// https://github.com/ctimmerm/ApiService-mock-adapter
// https://it-qa.com/how-do-you-use-getters-in-actions-vuex/#How_do_you_use_getters_in_actions_VUEX

// MapState here
// https://jerickson.net/basics-of-mapstate-vuex/
// https://stackoverflow.com/questions/59176722/vuex-vuex-module-namespace-not-found-in-mapstate-mapgetters-x

import ApiService from "@/core/services/api.service";

// Setters
export const SET_USER = "setUser";
export const SET_DASHBOARD = "setDashboard";
export const SET_SETTINGS = "setSettings";
export const SET_DASHBOARD_SETTINGS = "setDashboardSettings";
export const CHANGE_DASHBOARD_SETTINGS_WIDGET = "changeDashboardSettingsWidget";
export const SET_PARTNERS = "setPartners";
export const SET_COUNTRIES = "setCountries";
export const SET_PALLETS = "setPallets";
export const SET_BALANCES = "setBalances";
export const SET_STOCKS = "setStocks";
export const SET_REPORTS = "setReports";

export const LOAD_USER_ID = "loadUserID";

// Getters
// export const GET_USERID = "getUserUserID";
//export const GET_HAS_OPERATION_DRAFT = "getHasOperationDraft";

// Actions
export const CHANGE_WIDGET_ORDER = "changeWidgetOrder";
export const CHANGE_WIDGET_VISIBLE = "changeWidgetVisible";
export const INC_LOADING = "incLoading";
export const DEC_LOADING = "decLoading";
export const RESET_LOADING = "resetLoading";
export const INC_OPERATION_TID = "incOperationTID";
export const UPDATE_FILTERED_OPERATIONS = "updateFilteredOperations";
export const RESET_FILTERED_OPERATIONS = "resetFilteredOperations";

export const HAS_OPERATION_DRAFT = "hasOperationDraft";
export const RESET_OPERATION_DRAFT = "resetOperationDraft";
export const HAS_PARTNER_DRAFT = "hasPartnerDraft";
export const RESET_PARTNER_DRAFT = "resetPartnerDraft";
export const RESET_ALL_DRAFT = "resetAllDraft";

export default {
  //namespaced: true,
  state: {
    loading: 0,
    OperationTID: 1,
    filteredOperations: {},
    OperationDraft: false,
    PartnerDraft: false,
    UserID: 0,
    //
    User: [],
    Dashboard: [
      {
        WidgetName: "ActivityWidget",
      },
      {
        WidgetName: "BalanceWidget",
      },
      {
        WidgetName: "AlertsWidget",
      },
      {
        WidgetName: "CreditsWidget",
      },
      {
        WidgetName: "DebtsWidget",
      },
      {
        WidgetName: "TotalsWidget",
      },
      {
        WidgetName: "LatestOperationsWidget",
      },
      {
        WidgetName: "NewsWidget",
      },
      {
        WidgetName: "ContactWidget",
      },
    ], // Fix avoid loading errors for components
    Settings: [],
    Partners: [],
    Countries: [],
    Pallets: [],
    Balances: [],
    Stocks: [],
    Reports: [],
  },
  getters: {
    // UserID
    getUserID(state) {
      return state.UserID;
    },
    //
    getOperationTID(state) {
      return state.OperationTID;
    },
    getFilteredOperations(state) {
      return state.filteredOperations;
    },
    // Draft
    getHasOperationDraft(state) {
      return state.OperationDraft;
    },
    getHasPartnerDraft(state) {
      return state.PartnerDraft;
    },
    getPreviousOperationID: (state) => (id) => {
      if (state.filteredOperations.IDs === null) return undefined;
      let key = state.filteredOperations.IDs.indexOf(id); // V4 + V6 ok with OperationUID hash for V6
      let pid = Number(key) > 0 ? Number(key) - 1 : key;
      return typeof state.filteredOperations.IDs[pid] !== "undefined" && state.filteredOperations.IDs[pid] !== id
        ? state.filteredOperations.IDs[pid]
        : undefined;
    },
    getNextOperationID: (state) => (id) => {
      if (state.filteredOperations.IDs === null) return undefined;
      let key = state.filteredOperations.IDs.indexOf(id); // V4 + V6 ok with OperationUID hash for V6
      let nid = Number(key) < state.filteredOperations.IDs.length ? Number(key) + 1 : key;
      return typeof state.filteredOperations.IDs[nid] !== "undefined" && state.filteredOperations.IDs[nid] !== id
        ? state.filteredOperations.IDs[nid]
        : undefined;
    }, // User
    getUser(state) {
      return state.User;
    },
    getUserCapability: (state) => (capability) => {
      return state.User.Capabilities !== undefined ? state.User.Capabilities.includes(capability) : false;
    },
    getUserClientID(state) {
      return state.User.ClientID !== undefined ? state.User.ClientID : null;
    },
    getUserGetOperationsLimit(state) {
      return state.User.ListedOperationsLimit !== undefined ? parseInt(state.User.ListedOperationsLimit) : 100;
    },
    getUserPartner(state) {
      try {
        return state.User.ClientID !== undefined ? state.Partners.find((p) => p.PartnerID === state.User.ClientID) : null;
      } catch (e) {
        console.error("[ERROR]getUserPartner::", e);
      }
    },
    getUserName(state) {
      return state.User.UserName !== undefined ? state.User.UserName : null;
    },
    getUserClientName(state) {
      return state.User.InitialClientName !== undefined ? state.User.InitialClientName : null;
    },
    getUserFullName(state) {
      return state.User.UserName !== undefined && state.User.InitialClientName !== undefined
        ? state.User.UserName + " (" + state.User.InitialClientName + " )"
        : null;
    },
    getUserEmails(state) {
      return state.User.UserEMail !== undefined ? [state.User.UserEMail] : null;
    },
    getUserPartnerName(state) {
      try {
        return state.User.ClientID !== undefined ? state.Partners.find((p) => p.PartnerID === state.User.ClientID).Name : null;
      } catch (e) {
        console.error("[ERROR]getUserPartnerName::", e);
      }
    },
    getUserMainRole(state) {
      try {
        return state.User.MainRole !== undefined ? state.User.MainRole : null;
      } catch (e) {
        console.error("[ERROR]getUserMainRole::", e);
      }
    },
    getUserCountry(state) {
      try {
        return state.User.CountryISO !== undefined ? state.User.CountryISO : null;
      } catch (e) {
        console.error("[ERROR]getUserCountry::", e);
      }
    },
    // Dashboard
    getDashboard(state) {
      return state.Dashboard;
    },
    getDashboardByWidgetName: (state) => (name) => {
      //return state.Dashboard.find(p => p.WidgetName === name); // Single
      return state.Dashboard.filter((p) => p.WidgetName === name); // Recursive
    },
    // Settings
    getSettings(state) {
      return state.Settings;
    },
    getDashboardSettings(state) {
      return state.Settings.DashboardSettings;
    },
    // Partners
    getPartners(state) {
      return state.Partners;
    },
    getPartnersFor: (state) => (key) => {
      try {
        return state.Partners.filter((p) => p.UsedFor.includes(key));
      } catch (e) {
        console.error("[ERROR]getPartnersFor::", e);
      }
    },
    getPartnerByPartnerID: (state) => (id) => {
      try {
        //return state.Partners.find((p) => p.PartnerID === id);
        return id !== undefined && id !== 0 && id !== -1 ? state.Partners.find((p) => p.PartnerID === id) : undefined; // Changed from null
      } catch (e) {
        console.error("[ERROR]getPartnerByPartnerID::", e);
      }
    },
    getPartnerByGlobalID: (state) => (id) => {
      try {
        return state.Partners.find((p) => p.GlobalID === id);
      } catch (e) {
        console.error("[ERROR]getPartnerByGlobalID::", e);
      }
    },
    getPreviousPartnerByGlobalID: (state) => (id) => {
      let key = Object.keys(state.Partners).find((key) => state.Partners[key].GlobalID === id);
      let pid = Number(key) > 0 ? Number(key) - 1 : key;
      return typeof state.Partners[pid] !== "undefined" ? state.Partners[pid].GlobalID : undefined;
    },
    getNextPartnerByGlobalID: (state) => (id) => {
      let key = Object.keys(state.Partners).find((key) => state.Partners[key].GlobalID === id);
      let nid = Number(key) < state.Partners.length ? Number(key) + 1 : key;
      return typeof state.Partners[nid] !== "undefined" ? state.Partners[nid].GlobalID : undefined;
    },
    getPreviousPartnerByPartnerID: (state) => (id) => {
      let key = Object.keys(state.Partners).find((key) => state.Partners[key].PartnerID === id);
      let pid = Number(key) > 0 ? Number(key) - 1 : key;
      return typeof state.Partners[pid] !== "undefined" && state.Partners[pid].PartnerID !== id ? state.Partners[pid].PartnerID : undefined;
    },
    getNextPartnerByPartnerID: (state) => (id) => {
      let key = Object.keys(state.Partners).find((key) => state.Partners[key].PartnerID === id);
      let nid = Number(key) < state.Partners.length ? Number(key) + 1 : key;
      return typeof state.Partners[nid] !== "undefined" && state.Partners[nid].PartnerID !== id ? state.Partners[nid].PartnerID : undefined;
    },
    isPartners(state) {
      return state.Partners !== undefined ? true : false;
    },
    // Countries
    getCountries(state) {
      return state.Countries;
    },
    // Pallets
    getPallets(state) {
      return state.Pallets;
    },
    getDefaultPallets(state) {
      try {
        return state.Pallets.filter((p) => p.ByDefault === true); // Non-reactivity bug : Object.freeze KO
      } catch (e) {
        console.error("[ERROR]getDefaultPallets::", e);
      }
    },
    getMorePallets(state) {
      try {
        return state.Pallets.filter((p) => p.ByDefault === false && p.DisplayOrder > 0);
      } catch (e) {
        console.error("[ERROR]getMorePallets::", e);
      }
    },
    getDisplayPallets(state) {
      try {
        return state.Pallets.filter((p) => p.DisplayOrder > 0);
      } catch (e) {
        console.error("[ERROR]getDisplayPallets::", e);
      }
    },
    getPallet: (state) => (id) => {
      try {
        //return state.Pallets.find((p) => p.PalletID === id);
        if (state.Pallets.length > 0) return id !== undefined && id !== 0 && id !== -1 ? state.Pallets.find((p) => p.PalletID === id) : undefined;
        else return undefined;
      } catch (e) {
        console.error("[ERROR]getPallet::", e);
      }
    },
    // Balances
    getBalances(state) {
      return state.Balances;
    },
    getBalanceByPartnerID: (state) => (id) => {
      return state.Balances.find((b) => b.PartnerID === id);
    },
    getBalanceByPalletID: (state) => (id) => {
      return state.Balances.find((b) => b.PalletID === id);
    },
    // Stocks
    getStocks(state) {
      return state.Stocks;
    },
    // Reports
    getReports(state) {
      return state.Reports;
    },
  },
  mutations: {
    // UserID
    setUserID(state, data) {
      state.UserID = data;
    },
    // Loading
    startLoading(state) {
      state.loading += 1;
    },
    stopLoading(state) {
      state.loading -= 1;
    },
    resetLoading(state) {
      state.loading = 0;
    },
    // OperationTID
    incOperationTID(state) {
      state.OperationTID += 1;
    },
    // Set filtered operations
    setFilteredOperations(state, data) {
      console.log("setFilteredOperations::", data, { ...state.filteredOperations, ...data });
      state.filteredOperations = { ...state.filteredOperations, ...data }; //Merge instead
    },
    // Reset filtered operations
    resetFilteredOperations(state) {
      console.log("resetFilteredOperations::");
      state.filteredOperations = {};
    },
    // Draft
    hasOperationDraft(state) {
      state.OperationDraft = true;
    },
    resetOperationDraft(state) {
      state.OperationDraft = false;
    },
    hasPartnerDraft(state) {
      state.PartnerDraft = true;
    },
    resetPartnerDraft(state) {
      state.PartnerDraft = false;
    },
    //
    // Set User datas
    [SET_USER](state, data) {
      state.User = data;
    },
    // Set Dashboard datas
    [SET_DASHBOARD](state, data) {
      state.Dashboard = data;
    },
    // Set User Settings datas
    [SET_SETTINGS](state, data) {
      state.Settings = data;
    },
    // Set User DashboardSettings datas
    [SET_DASHBOARD_SETTINGS](state, DashboardSettings) {
      state.Settings.DashboardSettings = DashboardSettings;
    },
    // Set User DashboardSettings widget order by id
    [CHANGE_DASHBOARD_SETTINGS_WIDGET](state, payload) {
      const { widgetKey, widgetValue, key } = payload;
      console.log("CHANGE_DASHBOARD_SETTINGS_WIDGET", widgetKey, widgetValue, key);

      // OLD : Array way
      // let index = state.Settings.DashboardSettings.findIndex((w) => w.Name === widgetKey);
      // state.Settings.DashboardSettings[index][key] = widgetValue;

      // NEW : Object way
      if (typeof state.Settings.DashboardSettings[widgetKey][key] !== "undefined") state.Settings.DashboardSettings[widgetKey][key] = widgetValue;
      // console.log("CHANGE_DASHBOARD_SETTINGS_WIDGET::AFTER", widgetKey, widgetValue, key, state.Settings.DashboardSettings[widgetKey][key]);

      // // Reorder object with a timeOut
      // setTimeout(() => {
      //   if (key === "Order") {
      //     // state.Settings.DashboardSettings = state.Settings.DashboardSettings.slice().sort(
      //     //   function(a, b) {
      //     //     return a.Order - b.Order;
      //     //   }
      //     // );
      //     _.orderBy(state.Settings.DashboardSettings, "Order");
      //     console.log("CHANGE_DASHBOARD_SETTINGS_WIDGET::As been reordered");
      //   }
      // }, 2000); // Not needed
    },
    // Set Partners datas
    [SET_PARTNERS](state, data) {
      state.Partners = data;
    },
    // Set Countries datas
    [SET_COUNTRIES](state, data) {
      state.Countries = data;
    },
    // Set Pallets datas
    [SET_PALLETS](state, data) {
      state.Pallets = data;
    },
    // Set Balances datas
    [SET_BALANCES](state, data) {
      state.Balances = data;
    },
    // Set Stocks datas
    [SET_STOCKS](state, data) {
      state.Stocks = data;
    },
    // Set Reports datas
    [SET_REPORTS](state, data) {
      state.Reports = data;
    },
  },
  actions: {
    // UserID
    [LOAD_USER_ID](context, payload) {
      context.commit("setUserID", payload);
    },
    //
    [INC_LOADING]({ commit }) {
      commit("startLoading");
    },
    [DEC_LOADING]({ commit }) {
      commit("stopLoading");
    },
    [RESET_LOADING]({ commit }) {
      commit("resetLoading");
    },
    [INC_OPERATION_TID]({ commit }) {
      commit("incOperationTID");
    },
    [UPDATE_FILTERED_OPERATIONS](context, payload) {
      context.commit("setFilteredOperations", payload);
    },
    [RESET_FILTERED_OPERATIONS]({ commit }) {
      commit("resetFilteredOperations");
    },
    // Draft
    [HAS_OPERATION_DRAFT]({ commit }) {
      commit("hasOperationDraft");
    },
    [RESET_OPERATION_DRAFT]({ commit }) {
      commit("resetOperationDraft");
    },
    [HAS_PARTNER_DRAFT]({ commit }) {
      commit("hasPartnerDraft");
    },
    [RESET_PARTNER_DRAFT]({ commit }) {
      commit("resetPartnerDraft");
    },
    [RESET_ALL_DRAFT]({ commit }) {
      commit("resetOperationDraft");
      commit("resetPartnerDraft");
    },
    //
    async loadPartners({ commit, state }) {
      if (state.Partners.length > 0) console.info("Partners::Already loaded");
      commit("startLoading");
      //await ApiService.get("/Partners", { a: 10, b: "ok?" }).then(function(response) {
      await ApiService.get("/partners")
        .then(function (response) {
          //console.log("/Partners:: " + response.status + JSON.stringify(response));
          commit("setPartners", response.data.Partners);
        })
        .catch(function (error) {
          console.log("/partners::load error no response" + JSON.stringify(error));
          // @Justin / @Wil :: ici dois-je catcher l'erreur ?
        });
      commit("stopLoading");
    },
    async loadUser({ commit, state }) {
      if (state.User.length > 0) console.info("User::Already loaded");
      commit("startLoading");
      //      await ApiService.get("/User", "ID=100").then(function(response) {
      await ApiService.get("/user")
        .then(function (response) {
          //console.log("/User:: " + response.status + JSON.stringify(response));
          let data = response.data.User;
          for (var i = 0; i < data.AuthorizedClients.length; i++) {
            try {
              data.AuthorizedClients[i].ClientName = state.Partners.find((p) => p.PartnerID == data.AuthorizedClients[i].ClientID).Name;
              data.AuthorizedClients[i].ClientCity = state.Partners.find((p) => p.PartnerID == data.AuthorizedClients[i].ClientID).City;
            } catch (e) {
              console.log("[WARNING] loadUser::AuthorizedClients:: Partner is not defined " + e);
            }
          }
          commit("setUser", data);
          let settings = response.data.Settings;
          commit("setSettings", settings);
        })
        .catch(function (error) {
          console.log("/user::load error no response" + JSON.stringify(error));
        });
      commit("stopLoading");
    },
    async loadDashboard({ commit, state }) {
      if (state.Dashboard.length > 0) console.info("Dashboard::Already loaded");
      commit("startLoading");
      await ApiService.get("/dashboard")
        .then(function (response) {
          //console.log("/Dashboard:: " + response.status + JSON.stringify(response));
          commit("setDashboard", response.data.Widgets);
        })
        .catch(function (error) {
          console.log("/dashboard::load error no response" + JSON.stringify(error));
        });
      commit("stopLoading");
    },
    async loadCountries({ commit, state }) {
      if (state.Countries.length > 0) console.info("Countries::Already loaded");
      commit("startLoading");
      await ApiService.get("/countries")
        .then(function (response) {
          //console.log("/Countries:: " + response.status + JSON.stringify(response));
          commit("setCountries", response.data.Countries);
        })
        .catch(function (error) {
          console.log("/countries::load error no response" + JSON.stringify(error));
        });
      commit("stopLoading");
    },
    async loadPallets({ commit, state }) {
      if (state.Pallets.length > 0) console.info("Pallets::Already loaded");
      commit("startLoading");
      await ApiService.get("/pallets")
        .then(function (response) {
          //console.log("/Pallets:: " + response.status + JSON.stringify(response));
          commit("setPallets", response.data.Pallets);
        })
        .catch(function (error) {
          console.log("/pallets::load error no response" + JSON.stringify(error));
        });
      commit("stopLoading");
    },
    async loadBalances({ commit, state }) {
      if (state.Balances.length > 0) console.info("Balances::Already loaded");
      commit("startLoading");
      await ApiService.get("/balances")
        .then(function (response) {
          //console.log("/Balances:: " + response.status + JSON.stringify(response));
          commit("setBalances", response.data.Balances);
          commit("setStocks", response.data.Stocks);
        })
        .catch(function (error) {
          console.log("/balances::load error no response" + JSON.stringify(error));
        });
      commit("stopLoading");
    },
    async loadReports({ commit, state }) {
      if (state.Reports.length > 0) console.info("Reports::Already loaded");
      commit("startLoading");
      await ApiService.get("/stats")
        .then(function (response) {
          //console.log("/Balances:: " + response.status + JSON.stringify(response));
          commit("setReports", response.data);
        })
        .catch(function (error) {
          console.log("/stats::load error no response" + JSON.stringify(error));
        });
      commit("stopLoading");
    },
    [CHANGE_WIDGET_ORDER](context, payload) {
      payload.key = "Order";
      context.commit(CHANGE_DASHBOARD_SETTINGS_WIDGET, payload);
    },
    [CHANGE_WIDGET_VISIBLE](context, payload) {
      payload.key = "Visible";
      context.commit(CHANGE_DASHBOARD_SETTINGS_WIDGET, payload);
    },
  },
};
