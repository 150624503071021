// action types
//export const ADD_DEVICE = "addDevice";

// mutation types
export const SET_DEVICE = "setDevice";

export default {
  state: {
    device: undefined,
  },
  getters: {
    getDevice: (state) => {
      return state.device;
    },
  },
  actions: {
    [SET_DEVICE](context, payload) {
      context.commit(SET_DEVICE, payload);
    },
  },
  mutations: {
    [SET_DEVICE](state, payload) {
      state.device = payload;
    },
  },
};
