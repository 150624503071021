import Vue from "vue";
import Vuex from "vuex";

// Generic store
import store from "./store.module";

// Specific stores
import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";

// NeoOpatrace stores
import datas from "./neoopatrace/datas.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    store,
    auth,
    datas,
    htmlClass,
    config,
    breadcrumbs,
  },
});
