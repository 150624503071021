import ApiService from "@/core/services/api.service";
import storage from "@/core/services/storage.service";

// action types
export const INIT = "init";
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
// export const REGISTER = "register";
// export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setAuth";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_API_BASE_URL = "setApiBaseURL";

export const DONE_GETTING_BASE_URL = "doneGettingBaseURL";

const state = {
  errors: null,
  user: {},
  isAuthenticated: storage.isAuthenticated(),
  //
  appVersion: process.env.VUE_APP_VERSION || "0",
  baseURL: process.env.VUE_APP_BASE_URL || null,
  apiBaseURL: process.env.VUE_APP_API_BASE_URL || null,
  overriddenBaseURL: false,
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  appVersion(state) {
    return state.appVersion;
  },
  baseURL(state) {
    return state.baseURL;
  },
  apiBaseURL(state) {
    return state.apiBaseURL;
  },
  isOverriddenBaseURL(state) {
    return state.overriddenBaseURL;
  },
};

const actions = {
  [INIT](context) {
    // A
    // return new Promise((resolve, reject) => {
    //   ApiService.setHeader();
    //   // Get first root api
    //   ApiService.get("getrootapi")
    //     .then(({ data }) => {
    //       console.log("Get root api:: ", data);
    //       context.commit(SET_API_BASE_URL, data);
    //       resolve(data);
    //     })
    //     .catch(({ response }) => {
    //       response = response === undefined ? "Get root api::Error, cannot reach the server" : response.data.errors;
    //       context.commit(SET_ERROR, response);
    //       reject(response);
    //     });
    // });
    // B
    // ApiService.setHeader();
    // context.dispatch(ON_GETTING_BASE_URL);
    // ApiService.get("getrootapi")
    //   .then(({ data }) => {
    //     console.log("[AUTH] Get root api::baseURL ", data);
    //     context.commit(SET_API_BASE_URL, data);
    //     context.dispatch(DONE_GETTING_BASE_URL);
    //   })
    //   .catch(({ response }) => {
    //     response = response === undefined ? "[AUTH] Get root api::baseURL:: Error, cannot reach the server" : response.data.errors;
    //     context.commit(SET_ERROR, response);
    //     context.dispatch(DONE_GETTING_BASE_URL);
    //   });
    // C
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      // overriddenBaseURL
      if (state.overriddenBaseURL === true) {
        console.log("[AUTH] We already received a BaseURL, so we resolve the promise ");
        resolve({ data: state.apiBaseURL });
      }
      console.info(
        "[AUTH] auth.module:: isConfigLoaded ?",
        storage.isConfigLoaded(),
        "isAuthenticated?",
        state.isAuthenticated,
        "overriddenBaseURL?",
        state.overriddenBaseURL
      );
      // Get config
      if (!storage.isConfigLoaded(false)) {
        // Get first root api
        ApiService.getConfig("/")
          .then(({ data }) => {
            console.log("[AUTH] Get config api::baseURL (/)", data);
            context.commit(SET_API_BASE_URL, data.RootAPI);
            context.dispatch(DONE_GETTING_BASE_URL);
            storage.configLoaded(true);
            resolve(data);
          })
          .catch(({ response }) => {
            ApiService.getConfig("/neoopatrace/")
              .then(({ data }) => {
                console.log("[AUTH] Get config api::baseURL (/neoopatrace/)", data);
                context.commit(SET_API_BASE_URL, data.RootAPI);
                context.dispatch(DONE_GETTING_BASE_URL);
                storage.configLoaded(false);
                resolve(data);
              })
              .catch(({ response }) => {
                response = response === undefined ? "[AUTH][ERROR] Get config api::baseURL::Error, cannot reach the server" : response.data.errors;
                context.commit(SET_ERROR, response);
                context.dispatch(DONE_GETTING_BASE_URL);
                storage.configLoaded(false);
                reject(response);
              });
            //          response = response === undefined ? "[AUTH][ERROR] Get config api::baseURL::Error, cannot reach the server" : response.data.errors;
            //          context.commit(SET_ERROR, response);
            //          context.dispatch(DONE_GETTING_BASE_URL);
            //          reject(response);
          });
      }
    });
  },
  [DONE_GETTING_BASE_URL]({ commit }) {
    commit("getOverriddenBaseURL");
  },
  [LOGIN](context, credentials) {
    console.log("[LOGIN]", context, credentials);
    return new Promise((resolve, reject) => {
      // Live & mock version
      ApiService.setHeader();
      // Open session
      //      ApiService.get("/opensession", `user=${credentials.user}&pass=${credentials.pass}`) // V4
      ApiService.get("/opensession", `login=${credentials.user}&pass=${credentials.pass}&cookie=true`) // V6 / cookie=true
        .then(({ data }) => {
          console.log("[LOGIN] Here what post returns", data);
          context.commit(SET_AUTH, data);
          storage.setDisconnectInformations({ route_name: "login" });
          resolve(data);
        })
        .catch(({ response }) => {
          response = response === undefined ? "Error, cannot reach the server" : response.data.errors;
          context.commit(SET_ERROR, response);
          reject(response);
        });
    });
  },
  [LOGOUT](context) {
    console.log("[LOGOUT]", context);
    return new Promise((resolve, reject) => {
      // Live & mock version
      // ApiService.setHeader();
      // Open session
      ApiService.get("/closesession")
        .then(({ data }) => {
          console.log("[LOGOUT] Here what post returns", data);
          context.commit(PURGE_AUTH);
          resolve(data);
        })
        .catch(({ response }) => {
          response = response === undefined ? "Error, cannot reach the server" : response.data.errors;
          context.commit(SET_ERROR, response);
          reject(response);
        });
    });
  },
  // [REGISTER](context, credentials) {
  //   return new Promise(resolve => {
  //     ApiService.post("login", credentials)
  //       .then(({ data }) => {
  //         context.commit(SET_AUTH, data);
  //         resolve(data);
  //       })
  //       .catch(({ response }) => {
  //         context.commit(SET_ERROR, response.data.errors);
  //       });
  //   });
  // },
  [VERIFY_AUTH](context) {
    return new Promise((resolve, reject) => {
      // First check if we have a config > if not reject
      if (storage.isConfigLoaded() === true) {
        // Then check if we have a session
        // https://api.opatrace.com/api/noop
        ApiService.getAuth()
          .then(({ data }) => {
            console.log("[VERIFY_AUTH] Config & auth ok, here what /noop returns", data);
            context.commit(SET_AUTH, data);
            resolve(data);
          })
          .catch(({ response }) => {
            console.log("[VERIFY_AUTH] No auth");
            response = response === undefined ? "[VERIFY_AUTH] No auth" : response.data.errors;
            context.commit(PURGE_AUTH);
            context.commit(SET_ERROR, response);
            reject("NoAuth");
          });
      } else {
        console.log("[VERIFY_AUTH] No config");
        context.commit(PURGE_AUTH);
        context.commit(SET_ERROR, "[VERIFY_AUTH] No config");
        reject("NoConfig");
      }
    });
  },
  // [UPDATE_PASSWORD](context, payload) {
  //   const password = payload;

  //   return ApiService.put("password", password).then(({ data }) => {
  //     context.commit(SET_PASSWORD, data);
  //     return data;
  //   });
  // }
};

const mutations = {
  getOverriddenBaseURL(state) {
    state.overriddenBaseURL = true;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    storage.authenticated(true);
    //storage.setDisconnectInformations({ route_name: "login" });
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    storage.destroyAuthenticated();
  },
  [SET_API_BASE_URL](state, url) {
    console.log("SET_API_BASE_URL baseURL", url, ApiService);
    state.apiBaseURL = url;
    //ApiService.defaults.baseURL = url; // Solution 1 ? KO
    ApiService.setBaseURL(url); // Solution 2 ? OK
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
