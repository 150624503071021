import Vue from "vue";
var vm = new Vue();

import VueRouter from "vue-router";
const { isNavigationFailure, NavigationFailureType } = VueRouter;

import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

//import Swal from "sweetalert2";

import router from "@/router";
import storage from "@/core/services/storage.service";
import store from "@/core/services/store";
import { INC_LOADING, DEC_LOADING, RESET_LOADING } from "@/core/services/store/neoopatrace/datas.module.js";
//import datas from "@/core/services/store/neoopatrace/datas.module.js"; // Trying to verify UserID
import { INIT, VERIFY_AUTH, LOGOUT } from "@/core/services/store/auth.module";

// /**
//  * Service to call HTTP request via Axios
//  */
// export async function getBaseUrl() {
//   var url = await store.getters.apiBaseURL;
//   return url;
// }

const ApiService = {
  init() {
    var _this = this;
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = store.getters.apiBaseURL + "/api";
    Vue.axios.defaults.withCredentials = true; // Bug with api call before login
    console.log("[APISERVICE] Init Vue.axios.defaults.baseURL::", store.getters.apiBaseURL);

    // First load config.json
    store.dispatch(INIT);

    // Intercept if baseURL is changing
    //https://github.com/axios/axios/issues/2448
    //https://forum.vuejs.org/t/how-to-globally-configure-axios-baseurl-for-all-vuex-module-actions/107426
    // Vue.axios.interceptors.request.use(
    //   async config => {
    //     if (config.url === "getrootapi") console.log("[APISERVICE] Config Call /getrootapi", config);
    //     config.baseURL = await getBaseUrl();
    //     return config;
    //   },
    //   error => Promise.reject(error)
    // );

    // Intercept axios requests and response
    // https://stackoverflow.com/questions/50768678/axios-ajax-show-loading-when-making-ajax-request
    // https://codesandbox.io/s/jvkx6l54v
    Vue.axios.interceptors.request.use(
      async function (config) {
        // Do something before request is sent
        console.log("[APISERVICE] Start Ajax Call", config, config.url);
        // Increase loading state
        store.dispatch(INC_LOADING);
        return config;
      },
      function (error) {
        // Do something with request error
        console.log("[APISERVICE] Error requesting the data");

        let title = vm.$gettext("Error requesting the data");
        let text = vm.$gettext("We are sorry, please check your internet connection or contact our support...");
        let code = 404;
        let get = "/?";

        // Show error
        window.swalError.fire({
          title: title,
          text: text,
          heightAuto: true,
          footer: `<code>${code} | ${get} ${config}</code>`,
        });

        // Decrease loading state
        store.dispatch(DEC_LOADING);
        return Promise.reject(error);
      }
    );
    Vue.axios.interceptors.response.use(
      function (response) {
        let get = typeof response.config.url !== "undefined" ? response.config.url : "";

        // Do something with response data
        console.log("[APISERVICE] Done with ajax call", response, response.config.url);

        // Abord, if we call some url
        if (get === "noop") {
          console.info("[APISERVICE] Do not handle response of this ajax call", get);
          store.dispatch(DEC_LOADING);
          return typeof response !== "undefined" ? response : null;
        }

        // First, Check if datas are mocked by MockService
        if (Vue.config.useMockService !== true) {
          // Trying to verify UserID
          if (typeof response.data.Status !== "undefined" && store.getters.getUserID !== null) {
            //First call UserID
            if (store.getters.getUserID === 0)
              store.dispatch("loadUserID", response.data.Status.UserID).then(() => {
                console.log("[APISERVICE] Successfully first stored Status UserID", response.data.Status.UserID);
              });

            console.log("[APISERVICE] Received Status UserID", response.data.Status.UserID, response.config.url);
            console.log("[APISERVICE] Stored Status UserID", store.getters.getUserID);

            if (response.config.url === "/closesession")
              store.dispatch("loadUserID", response.data.Status.UserID).then(() => {
                console.log("[APISERVICE] Successfully resetting stored Status UserID", 0);
              });

            if (Number(response.data.Status.UserID) !== Number(store.getters.getUserID) && response.config.url !== "closesession") {
              // Show error
              window.swalError.fire({
                title: vm.$gettext("Invalid UserID"),
                text: vm.$gettext("You have been disconnected due to a mismatch of account, please contact our support..."),
                footer: `<code>Your current UserID | ${store.getters.getUserID}</code>`,
              });

              // Remove auth storage @Justin
              storage.authenticated(false);

              //window.location = "/#/login";
              store.dispatch(LOGOUT).then(() => {
                // No matter what, reset loading store state to 0
                store.dispatch(RESET_LOADING);
                // Then, redirect
                // DEV : Debounce TEST W > KO
                //_.debounce(_this.redirectToDisconnect(), 50000);
                _this.redirectToDisconnect();
              });
            }
          }
        } else {
          console.info("Avoid checking UserID / MockService mode is :", Vue.config.useMockService);
        }

        store.dispatch(DEC_LOADING);

        return typeof response !== "undefined" ? response : null;
      },
      function (error) {
        // debugger; // Open debbuger
        // console.log("interceptors::", typeof error);
        // console.log("interceptors::", error);
        // console.log("interceptors::", typeof error.response);
        // console.log("interceptors::", error.response);
        // console.log("interceptors::", typeof error.response.status);

        let title = vm.$gettext("Unknowed Error");
        let text = vm.$gettext("We are sorry, please contact our support...");
        let code = 404;
        let get = typeof error.config.url !== "undefined" ? error.config.url : "";

        // Abord, if we call some url
        if (get === "noop") {
          console.info("[APISERVICE] Do not handle error of this ajax call", get);

          title = vm.$gettext("Unauthorized or session expired");
          text = vm.$gettext("We are sorry, please re-auth to access to NeoOpatrace...");
          code = "401+";

          // Show error
          window.swalError.fire({
            title: title,
            text: text,
            footer: `<code>${code} | ${get}</code>`,
          });

          // Decrement loading
          store.dispatch(DEC_LOADING);

          return Promise.reject(error);
        }

        // Do something with response error
        if (typeof error.response !== "undefined" && error.response.status === 401) {
          console.log("[APISERVICE][" + error.response.status + "] Unauthorized request => remove auth from storage");

          // Error content
          title = vm.$gettext("Unauthorized or session expired");
          text = vm.$gettext("We are sorry, please re-auth to access to NeoOpatrace...");
          code = 401;

          // DEV : test WIL > KO
          // var wait = _.throttle(
          //   function () {
          //     console.log("Test Throttle redirectToDisconnect");
          //   },
          //   40000,
          //   { trailing: false }
          // );

          // wait();

          // Remove auth storage @Justin
          storage.authenticated(false);

          //window.location = "/#/login";
          store.dispatch(LOGOUT).then(() => {
            // No matter what, reset loading store state to 0
            store.dispatch(RESET_LOADING);
            // Then, redirect
            // DEV : Debounce TEST W > KO
            //_.debounce(_this.redirectToDisconnect(), 50000);
            _this.redirectToDisconnect();
          });
        } else if (typeof error.response !== "undefined" && error.response.status === 404) {
          console.log("[APISERVICE] Error fetching the data");
          // Error content
          title = vm.$gettext("Error fetching the data");
          text = vm.$gettext("Please re-try, we are not able to get the data...");
          code = 404;
        } else if (typeof error.response !== "undefined" && error.response.status === 405) {
          console.log("[APISERVICE] Network error");

          // Error content
          title = vm.$gettext("Network error");
          text = vm.$gettext("Please check your connection, we are not able to get the data...");
          code = 405;
        } else if (typeof error.response !== "undefined" && error.response.status === 400) {
          console.log("[APISERVICE] Params error");

          // Error content
          title = vm.$gettext("Params error");
          text = vm.$gettext("We are sorry, please contact our support...");
          code = 400;
        } else if (typeof error.response !== "undefined" && error.response.status === 403) {
          console.log("[APISERVICE][" + error.response.status + "]  Forbidden");

          // Error content
          title = vm.$gettext("Unauthorized request or session failure");
          text = vm.$gettext("We are sorry, please contact our support...");
          code = 403;

          // Remove auth storage @Justin
          storage.authenticated(false);

          //window.location = "/#/login";
          store.dispatch(LOGOUT).then(() => {
            // No matter what, reset loading store state to 0
            store.dispatch(RESET_LOADING);
            // Then, redirect
            // DEV : Debounce TEST W > KO
            //_.debounce(_this.redirectToDisconnect(), 50000);
            _this.redirectToDisconnect();
          });
        } else if (typeof error.response !== "undefined" && error.response.status === 415) {
          console.log("[APISERVICE] Bad content-type");

          // Error content
          title = vm.$gettext("Bad content-type");
          text = vm.$gettext("We are sorry, please contact our support...");
          code = 415;
        } else if (typeof error.response !== "undefined" && error.response.status === 500) {
          console.log("[APISERVICE] Fatal Error");

          if (get.startsWith("/opensession")) {
            title = vm.$gettext("Unknown credentials");
            text = vm.$gettext("Please make sure your name and password are filled correclty or contact our support...");
            code = "500+";
          } else {
            // Error content
            title = vm.$gettext("Fatal error");
            text = vm.$gettext("We are sorry, please contact our support...");
            code = 500;
          }
        }

        // Show error
        window.swalError.fire({
          title: title,
          text: text,
          footer: `<code>${code} | ${get}</code>`,
        });

        // Decrement loading
        store.dispatch(DEC_LOADING);

        return Promise.reject(error); // @Wilhem : OR error.repsonse ?
      }
    );

    // ———— BEARER TESTs
    // Vue.axios.interceptors.request.use((config) => {
    //   console.info("config.url", config.url);
    //   console.info("api.refresh", api.refresh);
    //   // const tokens = store.getters["auth/getTokens"];

    //   // if (!tokens) {
    //   //   return config;
    //   // }

    //   // let token = tokens.access;

    //   // if (config.url === api.refresh) {
    //   //   token = tokens.refresh;
    //   // }

    //   // if (config.url === api.refresh) {
    //   //   config.headers.Authorization = `Basic VGFyYTpUYXRh`;
    //   // }
    //   return config;
    // });

    // ———— BEARER TESTs
    // Vue.axios.interceptors.request.use(
    //   (config) => {
    //     console.info("config", config);
    //     console.info("localStorage", localStorage);
    //     // A
    //     // const token = localStorage.getItem("authtoken");
    //     // Not working CORS error

    //     // B
    //     const token = JwtService.getToken();
    //     // Not working CORS error

    //     // C
    //     // const token = "VGFyYTpUYXRh";
    //     // Not working CORS error

    //     if (token) {
    //       config.headers["Authorization"] = `Basic ${token}`;
    //     }

    //     return config;
    //   },

    //   (error) => {
    //     return Promise.reject(error);
    //   }
    // );
  },

  redirectToDisconnect() {
    var disconnectInformations = storage.getDisconnectInformations();
    // Here, can we find the route.to.query ? to redirect pages
    //var query = router;
    console.log("[APISERVICE] redirectToDisconnect", disconnectInformations);

    if (disconnectInformations.url && disconnectInformations !== null) window.location = disconnectInformations.url;
    else if (disconnectInformations.route_name && disconnectInformations !== null)
      router.push({ name: disconnectInformations.route_name }).catch((failure) => {
        console.log("[APISERVICE] redirectToDisconnect::failure", failure);

        //https://router.vuejs.org/guide/advanced/navigation-failures.html#detecting-navigation-failures
        if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
          console.log("[APISERVICE] redirectToDisconnect::failure", "Error duplicated navigation");
          // Show error
          // Swal.fire({
          //   title: vm.$gettext("Error duplicated navigation"),
          //   text: vm.$gettext("Login in order to access the dashboard"),
          //   icon: "error",
          //   confirmButtonClass: "btn btn-outline-secondary",
          //   heightAuto: false,
          //   timer: 1200,
          //   showClass: {
          //     backdrop: "swal2-noanimation", // disable backdrop animation
          //     popup: "", // disable popup animation
          //     icon: "", // disable icon animation
          //   },
          //   hideClass: {
          //     popup: "", // disable popup fade-out animation
          //   },
          // });
        }
      });
    else router.push({ name: "404" });
  },

  setBaseURL(url) {
    // Get first root api
    console.info("[APISERVICE] Get root api::baseURL ", url);
    console.info("[APISERVICE] Get root api::baseURL in store ", store.getters.apiBaseURL);
    console.info("[APISERVICE] Get root api::baseURL in axios ", Vue.axios.defaults.baseURL);
    Vue.axios.defaults.baseURL = store.getters.apiBaseURL + "/api";
    console.info("[APISERVICE] Get root api::baseURL in axios (then)", Vue.axios.defaults.baseURL);
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    // V4 > was not used
    // Vue.axios.defaults.headers.common["Authorization"] = `Token ${JwtService.getToken()}`; // TODO : Remove JwtService
    // ———— BEARER TESTs
    // Set header axios authtoken Bearer v6
    Vue.axios.defaults.headers.common["Authorization"] = `Basic VGFyYTpUYXRh`;
    // Not working CORS error
    //https://blog.logrocket.com/using-axios-set-request-headers/
    // Vue.axios.defaults.headers.post["Authorization"] = `Basic VGFyYTpUYXRh`;
    // Not working password is asked
    // Vue.axios.defaults.headers.get["Authorization"] = `Basic VGFyYTpUYXRh`;
    // Not working CORS error
  },

  encodeQuery(data) {
    let query = "";
    for (let d in data) query += encodeURIComponent(d) + "=" + encodeURIComponent(data[d]) + "&";
    return query.slice(0, -1);
  },

  // query(resource, params) {
  //   return Vue.axios.get(resource, params).catch(error => {
  //     // console.log(error);
  //     throw new Error(`[ERROR] ApiService ${error}`);
  //   });
  // },

  getConfig(baseURL = "/") {
    return Vue.axios.get("config.json", { baseURL: baseURL }).catch((error) => {
      console.log(error);
    });
  },

  getAuth() {
    return Vue.axios.get("noop").catch((error) => {
      console.log(error);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {*}
   */
  get(resource, params) {
    console.log("[APISERVICE]::Get", resource, "Have params ? (typeof)", typeof params, "[AUTH] Check", storage.isAuthenticated(), storage.isConfigLoaded());

    if (typeof params === "string") params = encodeURI(params);
    if (typeof params === "object") params = this.encodeQuery(params);
    if (typeof params !== "undefined") console.log("[APISERVICE]::Params", resource, params, typeof params);

    if (params !== "" && params != undefined) {
      // We do have params
      // Ensure we checked auth before each call other calls
      // Anyway call opensession
      if ((storage.isAuthenticated() && storage.isConfigLoaded()) || resource == "/opensession")
        return Vue.axios.get(`${resource}?${params}`).catch((error) => {
          console.log("[APISERVICE][GET][ERROR] " + error);
          //throw new Error(`[ERROR][APISERVICE]${resource}:: no params ${error}`);
        });
      else {
        console.error("[APISERVICE] User is not auth OR config is not loaded > ABORD", storage.isAuthenticated(), storage.isConfigLoaded());
        // Show error
        window.swalToast.fire({
          title: vm.$gettext("Unauthorized or session expired"),
          text: vm.$gettext("Please wait or re-auth to access to NeoOpatrace..."),
          footer: `<code>401</code>`,
        });
        // No matter what, reset loading store state to 0
        store.dispatch(RESET_LOADING);
        // Reject promise
        return Promise.reject("User is not authenticated OR config is not loaded");
      }
    } else {
      // We do not have params
      // Ensure we checked auth before each call other calls
      // Anyway call noop & closesession
      if ((storage.isAuthenticated() && storage.isConfigLoaded()) || resource == "/noop" || resource == "/closesession")
        return Vue.axios.get(`${resource}`).catch((error) => {
          console.log("[APISERVICE][GET][ERROR] " + error);
          //throw new Error(`[ERROR][APISERVICE]${resource}:: no params ${error}`);
        });
      else {
        console.error("[APISERVICE] User is not auth OR config is not loaded > ABORD", storage.isAuthenticated(), storage.isConfigLoaded());
        // Show error
        window.swalToast.fire({
          title: vm.$gettext("Unauthorized or session expired"),
          text: vm.$gettext("Please wait or re-auth to access to NeoOpatrace..."),
          footer: `<code>401</code>`,
        });
        // No matter what, reset loading store state to 0
        store.dispatch(RESET_LOADING);
        // Reject promise
        return Promise.reject("User is not authenticated OR config is not loaded");
      }
    }
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params = []) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
