//https://vuejs.org/v2/guide/filters.html
//https://stackoverflow.com/questions/44786159/vuejs-filters-in-external-file
import * as moment from "moment";

const filters = [
  {
    name: "foo",
    execute: (value) => {
      return "bar";
    },
  },
  {
    name: "getDateFormat",
    execute: (date, format, language) => {
      // Localize moment
      if (language) moment.locale(language);
      // Process
      if (date !== "" && date !== null && date !== undefined && typeof date !== "undefined" && typeof format !== "undefined")
        switch (format) {
          case "LocaleDate":
            return new Date(date).toLocaleDateString();
            break;
          case "LocaleTime":
            return new Date(date).toLocaleTimeString();
            break;
          case "LocaleYear":
            return new Date(date).getFullYear();
            break;
          case "ISO":
            return moment(String(date)).format();
            break;
          case "Humanized":
            return moment(String(date)).fromNow(true);
            break;
          default:
            return moment(String(date)).format(format);
        }
      else return "—";
    },
  },
  {
    name: "getNumberFormat",
    execute: (value) => {
      return !isNaN(value) && value !== "" && value !== null && value !== undefined && typeof value !== "undefined"
        ? new Intl.NumberFormat().format(value).toLocaleString()
        : "—";
    },
  },
  {
    name: "getSafeObjectValue",
    execute: (object, value) => {
      return object !== undefined &&
        typeof object !== "undefined" &&
        typeof object[value] !== "undefined" &&
        value !== "" &&
        value !== null &&
        value !== undefined &&
        typeof value !== "undefined"
        ? object[value]
        : "—";
    },
  },
  {
    name: "safePrint",
    execute: (value) => {
      return value !== "" && value !== null && value !== undefined && typeof value !== "undefined" ? value : "—";
    },
  },
  // {{ amount | pluralize('cookie') }}
  // {{ amount | pluralize('lady', 'ladies') }}
  {
    name: "pluralize",
    execute: (amount, singular, plural = `${singular}s`) => {
      return amount > 1 || amount === 0 ? plural : singular;
    },
  },
];
export default filters;
