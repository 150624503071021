const IS_AUTHENTICATED = "isAuthApi";
const DISCONNECT_INFOS = "disconnectInformations";
var IS_CONFIGLOADED = false;

export const isAuthenticated = () => {
  return window.localStorage.getItem(IS_AUTHENTICATED) == "true"; // From true to false > W
};

export const authenticated = (isAuthenticated) => {
  window.localStorage.setItem(IS_AUTHENTICATED, isAuthenticated);
};

export const destroyAuthenticated = () => {
  window.localStorage.removeItem(IS_AUTHENTICATED);
};

export const isConfigLoaded = () => {
  return IS_CONFIGLOADED;
};

export const configLoaded = (isConfigLoaded) => {
  IS_CONFIGLOADED = isConfigLoaded;
};

export const setDisconnectInformations = (infos) => {
  console.log("setDisconnectInformations::", infos);
  window.localStorage.setItem(DISCONNECT_INFOS, JSON.stringify(infos));
};

export const getDisconnectInformations = () => {
  var data = JSON.parse(window.localStorage.getItem(DISCONNECT_INFOS));
  return data;
};

export default { isAuthenticated, authenticated, destroyAuthenticated, isConfigLoaded, configLoaded, setDisconnectInformations, getDisconnectInformations };
