//https://stackoverflow.com/questions/39373047/include-global-functions-in-vue-js

import { _ } from "core-js";

export const mainFunctions = {
  // Function from bootstrap vue
  // Converts a value (string, number, etc.) to a number
  toFloat(value, defaultValue = NaN) {
    const float = parseFloat(value);
    return isNaN(float) ? defaultValue : float;
  },
  // Attempt to blur an element, and return `true` if successful
  attemptBlur(el) {
    try {
      el.blur();
    } catch {}
    return !this.isActiveElement(el);
  },
  // Attempt to focus an element, and return `true` if successful
  attemptFocus(el, options = {}) {
    try {
      el.focus(options);
    } catch {}
    return this.isActiveElement(el);
  },
  // Determine if an element is an HTML element
  isElement(o) {
    return typeof HTMLElement === "object"
      ? o instanceof HTMLElement //DOM2
      : o && typeof o === "object" && o !== null && o.nodeType === 1 && typeof o.nodeName === "string";
  },
  // Get the currently active HTML element
  getActiveElement(excludes = []) {
    const { activeElement } = document;
    return activeElement && !excludes.some((el) => el === activeElement) ? activeElement : null;
  },
  // Determine if an HTML element is the currently active element
  isActiveElement(el) {
    console.log("isActiveElement::", this.isElement(el), el === this.getActiveElement());
    return this.isElement(el) && el === this.getActiveElement();
  },
  isNull(value) {
    return value === null;
  },

  // Project functions
  // Returns the two first letters of a string
  getFirstLetters(str = "") {
    if (str == "") return "—";
    let matches = str.match(/\b(\w)/g); // ['J','s','o','n']
    if (matches != null) {
      return matches.slice(0, 2).join("").toUpperCase(); // JSON > Js > JS
    } else return "—";
  },

  // Diff
  // This is the function to execute, who called deepDiff
  diff(a = {}, b = {}, reversible = false, keepIndexes = false, keepKeys = []) {
    //    console.log("diff !!! ", a, b, keepIndexes);
    // From https://stackoverflow.com/questions/8572826/generic-deep-diff-between-two-objects
    // Modified @Justin
    var r = {};
    this.deepDiff(a, b, r, keepIndexes, keepKeys);
    if (reversible) this.deepDiff(b, a, r, keepIndexes, keepKeys);
    return r;
  },
  // Deep diff
  deepDiffKeepKeys(a, r, keepKeys) {
    // console.log("deepDiffKeepKeys ", a, Object.keys(a), keepKeys);
    let intersect = Object.keys(a).filter((v) => keepKeys.includes(v));
    // console.log("deepDiffKeepKeys intesect", intersect);
    if (intersect.length) {
      intersect.forEach((k) => {
        if (!_.isObject(a[k])) {
          // console.log("deepDiffKeepKeys foreach IN", k, a[k]);
          r[k] = a[k];
        }
      });
    }
  },
  isArrayOfNoneObject(data) {
    if (_.isArray(data)) {
      _.each(data, function (v, k) {
        if (!_.isString(v) && !_.isNumber(v)) return false;
        //        console.log("diff isArrayOfNoneObject => ", v, "is string or number");
      });
      return true;
    }
    return false;
  },
  deepDiff(a, b, r, keepIndexes = false, keepKeys = []) {
    // console.log("deepDiff", a);
    var _this = this;
    var changed = false;
    if (b == undefined) b = {};
    if (_.isArray(a) && _.isArray(b)) {
      if (_this.isArrayOfNoneObject(a) || _this.isArrayOfNoneObject(b)) {
        //  console.log("deepDiff => Array of a && b", JSON.stringify(a), JSON.stringify(b));
        if (JSON.stringify(a) != JSON.stringify(b)) {
          a = JSON.parse(JSON.stringify(a));
          b = JSON.parse(JSON.stringify(b));
        }
      }
    }
    _.each(a, function (v, k) {
      if (!keepIndexes && _.isArray(v)) {
        //  console.log("deepDiff => Array", k, v, b[k]);
        var t_r = [];
        if (_this.deepDiff(v, b[k], t_r, keepIndexes, keepKeys)) {
          if (_.isArray(r)) r.push(t_r);
          else r[k] = t_r;
          changed = true;
          //  console.log("deepDiffResult => Array", r, t_r);
          _this.deepDiffKeepKeys(a, r, keepKeys);
        }
        ////        changed = true;
        ////        _this.deepDiff(v, b[k], r[k]);
      } else if (_.isObject(v)) {
        // console.log("deepDiff => Object", k, v, b[k]);
        var t_r = {};
        if (_this.deepDiff(v, b[k], t_r, keepIndexes, keepKeys)) {
          if (_.isArray(r)) r.push(t_r);
          else r[k] = t_r;
          changed = true;
          _this.deepDiffKeepKeys(a, r, keepKeys);
          // console.log("deepDiffResult => Object", r);
        }
      } else {
        // console.log("deepDiff => Value", k, v, b[k]);
        if (r.hasOwnProperty(k) || b[k] === v) return;
        changed = true;
        r[k] = v;
        _this.deepDiffKeepKeys(a, r, keepKeys);
      }
      ////      r[k] = _.isObject(v) ? _this.diff(v, b[k], reversible) : v;
    });
    return changed;
  },

  // Used to copy a reactive obj to a non-reactive
  noReactiveCopy(value) {
    // console.log("noReactiveCopy", value);
    var t_value = {};
    var _this = this;
    if (_.isArray(value)) {
      t_value = Object.assign([], value);
      _.each(value, function (v, k) {
        if (_.isArray(v) || _.isObject(v)) t_value[k] = _this.noReactiveCopy(v);
      });
    } else if (_.isObject(value)) {
      t_value = Object.assign({}, value);
      _.each(value, function (v, k) {
        if (_.isArray(v) || _.isObject(v)) t_value[k] = _this.noReactiveCopy(v);
      });
    } else {
      t_value = value;
    }
    return t_value;
  },

  // Used to filter an obj and get only wanted keys : getFilteredObject(obj, ['PalletID', 'PalletName'])
  getFilteredObject(obj, keysTokeep = []) {
    return obj.map((o) => Object.fromEntries(keysTokeep.map((k) => [k, o[k]])));
    //return Object.fromEntries(Object.entries(obj).filter((n) => keysTokeep.includes(n[0])));
  },
};
