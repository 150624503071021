<template>
  <!-- <transition name="fade-in-up" mode="out-in"> -->
  <router-view></router-view>
  <!-- </transition> -->
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";
// Note Wilhem : See to remove all uneeded css

// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import { mapGetters } from "vuex";
//import ApiService from "@/core/services/api.service";

import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
//import storage from "@/core/services/storage.service";

export default {
  name: "App",
  data() {
    return {};
  },
  methods: {
    //...mapMutations(["setUser", "setSettings", "setPartners", "setCountries", "setPallets"]),
    loadAPI() {
      console.info("App.vue::loadAPI");

      // Reminder > Data order
      // user
      // partners
      // pallets
      // countries
      // dashboard
      // notifications
      // notificationscount
      // operation...

      // Load Partners
      this.$store.dispatch("loadPartners").then(() => {
        console.log("loadPartners");
      });

      // Load Pallets
      this.$store.dispatch("loadPallets").then(() => {
        console.log("loadPallets");
      });

      // Load Countries
      this.$store.dispatch("loadCountries").then(() => {
        console.log("loadCountries");
      });

      // To remove
      // // Load Balances
      // this.$store.dispatch("loadBalances").then(() => {
      //   console.log("loadBalances");
      // });

      // To remove
      // // Load Reports
      // this.$store.dispatch("loadReports").then(() => {
      //   console.log("loadReports");
      // });
    },
    // Global check auth
    checkAuthentification(auth, config) {
      console.log("[AUTH] checkAuthentification:: + config", auth, config);
      console.info("App.vue::Watch::[AUTH] [CONFIG]", "this.isOverriddenBaseURL", this.isOverriddenBaseURL, "this.isAuthenticated", this.isAuthenticated);

      if (!auth || !config) {
        console.info("App.vue::User is not authenticated or no config is getted -----------------------", this.$route, this.$router.currentRoute.name);
        console.info("@Wilhem App.vue:: DO WE NEED ? route to login here ? ");
        //if (this.$router.currentRoute.name !== "login" && this.$router.currentRoute.name !== null) this.$router.push({ name: "login" });
      } else {
        console.info("App.vue::User is authenticated and config is getted -----------------------", this.$route, this.$router.currentRoute.name);
        // Then delay page loading removing
        setTimeout(() => {
          // Remove page loader after some time
          //console.error("@Wilhem App.vue:: DO WE NEED ? this.loadAPI()");
          this.loadAPI();
        }, 2000);
      }
    },
  },
  watch: {
    isAuthenticated: {
      immediate: false, // Initiate at first load, trigger the callback immediately with the current value of the expression
      handler: function (n) {
        console.log("App.vue::Watch::isAuthenticated");
        this.checkAuthentification(n, this.isOverriddenBaseURL);
      },
    },
    isOverriddenBaseURL: {
      immediate: false, // Initiate at first load, trigger the callback immediately with the current value of the expression
      handler: function (n) {
        console.log("App.vue::Watch::isOverriddenBaseURL");
        this.checkAuthentification(this.isAuthenticated, n);
      },
    },
  },
  mounted() {
    console.info("App.vue");
    console.info("NeoOpatrace V4 | App version " + this.$store.getters.appVersion);
    console.info("NeoOpatrace V4 | Base URL " + this.$store.getters.baseURL);
    console.info("NeoOpatrace V4 | API base URL " + this.$store.getters.apiBaseURL);

    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);

    /**
     * Mount data
     */
    // Check if current user is authenticated and config
    this.checkAuthentification(this.isAuthenticated, this.isOverriddenBaseURL);
  },
  computed: {
    ...mapGetters(["isAuthenticated", "isOverriddenBaseURL"]),
  },
};
</script>
